exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-asunnot-asunto-js": () => import("./../../../src/pages/asunnot/[...asunto].js" /* webpackChunkName: "component---src-pages-asunnot-asunto-js" */),
  "component---src-pages-testi-js": () => import("./../../../src/pages/testi.js" /* webpackChunkName: "component---src-pages-testi-js" */),
  "component---src-pages-vuokraa-asunto-hakemus-js": () => import("./../../../src/pages/vuokraa-asunto/[...hakemus].js" /* webpackChunkName: "component---src-pages-vuokraa-asunto-hakemus-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-apartments-js": () => import("./../../../src/templates/apartments.js" /* webpackChunkName: "component---src-templates-apartments-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-customer-form-js": () => import("./../../../src/templates/CustomerForm.js" /* webpackChunkName: "component---src-templates-customer-form-js" */),
  "component---src-templates-gdpr-js": () => import("./../../../src/templates/gdpr.js" /* webpackChunkName: "component---src-templates-gdpr-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-housing-js": () => import("./../../../src/templates/Housing.js" /* webpackChunkName: "component---src-templates-housing-js" */),
  "component---src-templates-housings-js": () => import("./../../../src/templates/housings.js" /* webpackChunkName: "component---src-templates-housings-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */)
}

